import React from 'react'
import { MainLayout } from 'components/layouts'
import css from './style.module.scss'
import { UserSidebar, UserSettings } from 'components/user'
import SEO from 'components/seo'

const AccountSettingsPage = () => {
  return (
    <MainLayout>
      <SEO
        title='Настройки'
      />
      <div className={'container'}>
        <div className={css.accountPage}>

          <div className={css.title}>
            <h2>Личный кабинет</h2>
          </div>

          <div className={css.flexWrapper}>
            <div className={css.left}>
              <UserSidebar />
            </div>
            <div className={css.right}>
              <UserSettings />
            </div>
          </div> 

        </div>
      </div>
    </MainLayout>
  )
}

export default AccountSettingsPage